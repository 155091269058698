.languages {
	color: #e4e4e4;
	&:hover {
		color: #bbbbb6;
	}

	.dropdown {
		display: none;
		position: absolute;
		background-color: rgba($color: #e4e4e4, $alpha: 0.5);
		padding: 10px 10px;
		border-radius: 10px;
		z-index: 1;
		text-align: right;
		transform: translateX(-100%) translateX(30px);
	}
	&:hover .dropdown {
		display: block;
	}
}

.linkDiv {
	padding: 3px;
}

.link {
	color: #e4e4e4;
	text-decoration: none;
	&:hover {
		color: white;
	}
}

.linkCurrent {
	@extend .link;
	color: red;
	&:hover {
		color: pink;
	}
}
